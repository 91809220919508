import * as React from 'react';
import Metadata from '../components/Metadata';
import { faPhone, faEnvelope, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import Section from '../components/Section';
import Slider from '../components/Slider';

const StyledQuoteSection = styled.section`
  background-color: var(--color-primary);
  color: white;
  padding-block: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  p {
    font-family: 'Castoro', serif;
    font-style: italic;
    font-size: 2rem;
    width: 80%;
    max-width: 42ch;
    margin: 0 auto;
  }
`;

const StyledMainContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0;

  section (StyledSection) {
    width: 100%;
  }
`;

const StyledParagraph = styled.p`
  width: 80%;
  max-width: 70ch;
  text-align: center;
  display: flex;
  flex-direction: column;
`;

const StyledImageContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 1000px;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  display: block;
  color: var(--color-primary);

  @media (min-width: 576px) {
    margin-right: 0.5rem;
  }
`;

const StyledContactContainer = styled.div`
  margin-top: 2rem;

  @media (min-width: 576px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
`;

const StyledContactInfo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2rem;
  cursor: pointer;
  gap: 1rem;

  a {
    color: black;
    text-decoration: none;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    a,
    svg {
      color: #00176a;
    }
  }

  @media (min-width: 576px) {
    margin-bottom: 0;
  }
`;

const StyledAddress = styled.div`
  a {
    display: flex;
    flex-direction: column;
  }
`;

const IndexPage = () => {
  return (
    <>
      <Metadata title='Home' />
      <StyledMainContainer>
        <StyledImageContainer>
          <StaticImage
            className='bg-white'
            backgroundColor={'white'}
            src='../images/logo.png'
            alt='Logo'
          />
        </StyledImageContainer>
        <StyledQuoteSection>
          <StyledParagraph>
            <span>Wir alle können über uns hinauswachsen.</span>
            <span>Manches Mal bedarf es nur etwas Rückenwind.</span>
          </StyledParagraph>
        </StyledQuoteSection>
        <Section backgroundColor='secondary' heading='Angebote'>
          <p>
            Wir bieten Autismus-spezifische Fachleistungen für unterschiedliche
            Altersgruppen an. Dazu gehört in erster Linie Therapie für Menschen
            mit Autismus, aber auch die Beratung und Anleitung der
            Bezugspersonen und Institutionen aus dem sozialen Umfeld unserer
            Klient:innen.
          </p>
          <p>
            Unsere therapeutische und pädagogische Arbeit legt den Fokus auf
            individuelle Therapie in einem personenzentrierten Umfeld durch
            evidenzbasierte Methoden.
          </p>
          <p>
            Ein weiteres Angebot richtet sich an interessierte Institutionen,
            die Schulungen zum Thema Autismus und individualisierte
            Handlungsstrategien erhalten möchten.
          </p>
          <p>
            Sollten Ihnen die Informationen auf unserer Internetseite nicht
            ausreichen, kontaktieren Sie uns gerne.
          </p>
        </Section>
        <Section heading='Standorte & Kontakt' alignText='center'>
          <p>
            Unsere Räumlichkeiten befinden sich direkt in der Duisburger
            Stadtmitte, nur 5 Minuten Fußweg vom HBF Duisburg entfernt.
          </p>
          <p>
            Möchten Sie mehr über unsere Arbeit erfahren oder einen Termin zur
            Anmeldung vereinbaren?
          </p>
          <StyledContactContainer>
            <StyledContactInfo>
              <a href='tel:+4915738930161'>
                <StyledIcon icon={faPhone} size='2x' />
              </a>
              <p>
                <a href='tel:020336960370'>
                  Tel: &nbsp;&nbsp;&nbsp;&nbsp; 0203 36960370
                </a>
                <br />
                <a href='tel:+4915738930161'>Mobil: 0157 38930161</a>
              </p>
            </StyledContactInfo>
            <StyledContactInfo>
              <a href='mailto:info@lernen-rueckenwind.de'>
                <StyledIcon icon={faEnvelope} size='2x' />
              </a>
              <p>
                <a href='mailto:info@lernen-rueckenwind.de'>
                  info@lernen-rueckenwind.de
                </a>
              </p>
            </StyledContactInfo>

            <StyledContactInfo>
              <a
                href='https://goo.gl/maps/za9RiKQnHbA1kXsQ7'
                target='_blank'
                rel='noreferrer'
              >
                <StyledIcon icon={faHome} size='2x' />
              </a>
              <StyledAddress>
                <p>
                  <a
                    href='https://goo.gl/maps/za9RiKQnHbA1kXsQ7'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <span>Tonhallenstraße 19</span>
                    <span>Friedrich-Wilhelm-Str. 80</span>
                    <span>47051 Duisburg</span>
                  </a>
                </p>
              </StyledAddress>
            </StyledContactInfo>
          </StyledContactContainer>
        </Section>
        <Section backgroundColor={'secondary'} heading='Impressionen'>
          <Slider duration={2000}>
            <StaticImage
              src='../images/Friedrich-Wilhelm_2.jpg'
              alt='Standort Friedrich Wilhelm Str.'
            ></StaticImage>
            <StaticImage
              src='../images/Friedrich-Wilhelm_3.jpg'
              alt='Standort Friedrich Wilhelm Str.'
            ></StaticImage>
            <StaticImage
              src='../images/Friedrich-Wilhelm_4.jpg'
              alt='Standort Friedrich Wilhelm Str.'
            ></StaticImage>
            <StaticImage
              src='../images/Tonhallenstraße.jpg'
              alt='Standort Tonhallenstraße'
            ></StaticImage>
            <StaticImage
              src='../images/Tonhallenstraße_2.jpg'
              alt='Standort Tonhallenstraße'
            ></StaticImage>
          </Slider>
        </Section>
      </StyledMainContainer>
    </>
  );
};

export default IndexPage;
